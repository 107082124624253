import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  static USER_KEY: string = "user";
  static USER_TOKEN_KEY: string = "user-token";
  static REDIRECT_URL: string = 'redirect-url';
  static DATA_TO_SAVE: string = "DATA_TO_SAVE";

  constructor() {}

  write(key: string, value: any) {
    if (value) {
      this.remove(key);
      value = JSON.stringify(value);
      localStorage.setItem(key, value);
    }
  }

  read<T>(key: string): T {
    const value: string = localStorage.getItem(key);

    if (value && value !== "undefined" && value !== "null") {
      return <T>JSON.parse(value);
    }

    return null;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  removeAll() {
    localStorage.clear();
  }

  isExist(key: string) {
    return localStorage.getItem(key) != null;
  }
}
