import { Survey } from './../models/survey';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class SurveyService {
  constructor(private http: HttpClient) { }

  addSurvey(survey: Survey) {
    const url = environment.baseUrl + "/survey/add";
    return this.http.post(url, survey);
  }


  addSurveyOnModeOffline(surveys: Survey[]) {
    const url = environment.baseUrl + "/survey/add-survey-offline";
    return this.http.post(url, { "surveys": surveys });
  }

  getAllSurverys(
    page: number,
    perPage: number,
    search: string,
    user_id: number
  ) {
    const url =
      environment.baseUrl +
      `/survey/all?page=${page}&perPage=${perPage}&search=${search}&user_id=${user_id}`;
    return this.http.get(url);
  }

  getAllSurverysAdmin(
    page: number,
    perPage: number,
    search: string,
    user_id: number,
    type = null
  ) {
    const url =
      environment.baseUrl +
      `/admin/survey/all?page=${page}&perPage=${perPage}&search=${search}&user_id=${user_id}&type=${type}`;
    return this.http.get(url);
  }
  getSurvery(
    survey_id: number
  ) {
    const url =
      environment.baseUrl +
      `/survey/get/` + survey_id;
    return this.http.get(url);
  }

  getCompany(matricule_fiscal) {
    const url = environment.baseUrl + "/survey/company/" + matricule_fiscal;
    return this.http.get(url);
  }

  editSurvey(survey_id, survey: Survey) {
    const url = environment.baseUrl + "/survey/" + survey_id + "/edit";
    return this.http.put(url, survey);
  }

  
  getAllSurverysTOExcel(
    page: number,
    perPage: number,
    search: string,
    user_id: number,
    type = null
  ) {
    const url =
      environment.baseUrl +
      `/admin/survey/all-data?page=${page}&perPage=${perPage}&search=${search}&user_id=${user_id}&type=${type}`;
    return this.http.get(url);
  }
}
