<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <!-- <img src="assets/images/logo-light.svg" alt="" height="22"> -->
          </span>
          <span class="logo-lg">
            <!-- <img src="assets/images/logo-light.png" alt="" height="19"> -->
          </span>
        </a>
      </div>

      <!-- <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button> -->

      <!-- App Search-->
      <div  *ngFor="let item of menuItems" style="margin: auto; margin-left: -12%;" >
        <div>
          <!-- <li class="nav-item dropdown"> -->
            <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);" style="color: #ffff;"
              id="topnav-components" [routerLink]="item.link" role="button">
              <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }} <div class="arrow-down"
                *ngIf="hasItems(item)"></div>
            </a>

            <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
              href="javascript: void(0);" id="topnav-components" role="button">
              <i class="bx {{item.icon}} me-1"></i>
              {{item.label| translate }}
              <div class="arrow-down"></div>
            </a>

            <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11}" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
              <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                <a *ngIf="item.subItems.length < 11 && !hasItems(subitem)" class="col dropdown-item side-nav-link-ref"
                [routerLink]="subitem.link"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                 {{subitem.label | translate }}
            </a>
            <div *ngIf="item.subItems.length > 11">
                <div *ngIf="i % 3 == 0" class="row">
                    <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="subitem.link">{{item.subItems[i].label | translate}}</a></div>
                    <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 1]?.link">{{item.subItems[i + 1]?.label | translate}}</a></div>
                    <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 2]?.link">{{item.subItems[i + 2]?.label | translate}}</a></div>
                </div>
            </div>

              <div class="dropdown" *ngIf="hasItems(subitem)">
                <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                  (click)="onMenuClick($event)">{{ subitem.label | translate }}
                  <div class="arrow-down"></div>
                </a>
                <div class="dropdown-menu">
                  <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                    <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                      href="javascript: void(0);" [routerLink]="subSubitem.link"
                      routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                    <div class="dropdown" *ngIf="hasItems(subSubitem)">

                      <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                        (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                          <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                            [routerLink]="subSubSubitem.link"
                            routerLinkActive="active">{{ subSubSubitem.label | translate }}</a>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
              
            </ng-template>
           </div>
          <!-- </li> -->
          </div>    <!-- App Search-->
  </div>

    </div>
    <div class="d-flex">
<!--
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="ms-1" height="12"> <span
              class="align-middle">{{item.text | translate}}</span>
          </a>
        </div>
      </div> -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <div>
            <span ngbTooltip="" title="{{user.first_name}}  {{user.last_name}}"
                class="header-profile-user rounded-circle bg-soft bg-light text-primary font-size-32" style="padding-left: 8px;">
                {{getFristLettres(user)}}
            </span>
            <span class="d-none d-xl-inline-block ms-1">{{user.first_name}}  {{user.last_name}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </div>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'Logout' | translate}}</a>
        </div>
      </div>

    </div>
  </div>
</header>
<!-- <div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active show" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                id="topnav-components" [routerLink]="item.link" role="button">
                <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }} <div class="arrow-down"
                  *ngIf="hasItems(item)"></div>
              </a>

              <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                href="javascript: void(0);" id="topnav-components" role="button">
                <i class="bx {{item.icon}} me-1"></i>
                {{item.label| translate }}
                <div class="arrow-down"></div>
              </a>

              <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11}" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a *ngIf="item.subItems.length < 11 && !hasItems(subitem)" class="col dropdown-item side-nav-link-ref"
                  [routerLink]="subitem.link"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                   {{subitem.label | translate }}
              </a>
              <div *ngIf="item.subItems.length > 11">
                  <div *ngIf="i % 3 == 0" class="row">
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="subitem.link">{{item.subItems[i].label | translate}}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 1]?.link">{{item.subItems[i + 1]?.label | translate}}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 2]?.link">{{item.subItems[i + 2]?.label | translate}}</a></div>
                  </div>
              </div>

                <div class="dropdown" *ngIf="hasItems(subitem)">
                  <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                    (click)="onMenuClick($event)">{{ subitem.label | translate }}
                    <div class="arrow-down"></div>
                  </a>
                  <div class="dropdown-menu">
                    <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                      <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                        href="javascript: void(0);" [routerLink]="subSubitem.link"
                        routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                      <div class="dropdown" *ngIf="hasItems(subSubitem)">

                        <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                          (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                          <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu">
                          <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                            <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                              [routerLink]="subSubSubitem.link"
                              routerLinkActive="active">{{ subSubSubitem.label | translate }}</a>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
                
              </ng-template>
             </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div> -->
