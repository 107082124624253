import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { Subscription } from 'rxjs';
import { Survey } from 'src/app/core/models/survey';
import { User } from 'src/app/core/models/user';
import { SurveyService } from 'src/app/core/services/survey.service';
import * as XLSX from "xlsx";
import { Utils } from '../utils';
type AOA = any[][];
@Component({
  selector: 'app-list-sondage',
  templateUrl: './list-sondage.component.html',
  styleUrls: ['./list-sondage.component.scss']
})
export class ListSondageComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  dtOptions: any = {};
  page_length: number = null;
  currentPage: number = null;
  tableVisibility: boolean[] = [];
  searchInput = "";
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  busy: Subscription;
  params = new ParamPangination();
  surveys: Survey[] = [];
  @Input()
  user: User = new User();
  @Input()
  type: number ;
  user_id: number;
  surveysData: any[] = [];
  surveyExcelData: AOA = [
    ['type', "matricule_Fiscal", "raison_sociale", "année_création", "implantation_siège", "Avez-vous des filiales dans les régions?", "Est-ce une société Offshore ?", "Regime ?", "Est-ce que le dirigeant de cette société est ?", "Activité principale", "Sous Activité principale", "Produit principal", "% du CA annuel produit 1", "Activité secondaire", "Sous activité secondaire", "Produit secondaire", "% du CA annuel produit 2", "Avez-vous d’autres activités?", "Sites en Tunisie", 'Sites étrangers', 'Pays d’implantations sites étrangers', 'Chiffre d’affaires en Euros', '% CA dans l’automobile/aéronautique', '% CA à l’export', '% RD dans le CA', '% de la participation étrangère dans le Capital', 'Pays', 'Participation par Pays %', 'Capacité de production installée en %', 'Nom du client (Destinations Production)', 'Pays (Destinations Production)', 'Catégorie (Destinations Production)', 'Nombre (Constructeur)', '% dans le CA (Constructeur)', 'Nombre (Equipementier)', '% dans le CA (Equipementier)', 'Nombre (Tiers)', 'Total(Nombre d’employés directs)', '%Hommes(Nombre d’employés directs)', '%Femmes(Nombre d’employés directs)', 'Total(Nombre d’employés indirects)', '%Cadres', '%Techniciens', '%Ouvriers', '%Technique/Production', '%Commercial', '%Administratif', '%Contrats à durée indéterminée CDI', '%Contrats à durée déterminée CDD', '%SIVP/Karama/stages', '%Autres', 'Nombre d\'heures de formation délivrée par an', 'Avez-vous des recrutements prévus sur 12 mois?', 'Certifications Obtenues', 'Certifications en cours', 'Estimation du taux d’intégration local moyen de production',
      "Autres infos sur le savoir-faire technique de l’Entreprise", "Etes-vous ouverts à du Co-Dev. Avec techno-parcs, universités?", 'Rejet CO2', 'Revalorisation interne des déchets?', 'Déchets Plastique', 'Déchets Métalliques', 'Déchets Textiles et Cuirs', 'Déchets d’Huiles', 'Déchets Papiers et Cartons', 'Déchets Dangereux', 'Autres Déchets', 'Prévoyez-vous une extension durant ces 12 prochains mois ?', 'Prévoyez- vous de développer de nouveaux produits durant ces 12 prochains mois ?', 'Prévoyez-vous de développer de nouveaux marchés durant ces 12 prochains mois ?', 'Travaillez-vous sur une des tendances du secteur ?', 'Quelle est votre vision pour le développement de votre activité', 'Nom du répondant', 'Genre du répondant', 'Age du répondant', 'Fonction du répondant', 'Téléphone', 'Portable gérant', 'Fax', 'Email', 'Site web', 'Adresse exacte', 'Gouvernorat', 'Date de l’entretien', 'Enquêteur'
    ]
  ];
  constructor(private surveyService: SurveyService, private router: Router,
    private renderer: Renderer2,
  ) {
    this.breadCrumbItems = [{ label: 'Enquête' }, { label: 'Liste Enquête', active: true }];
  }

  ngOnInit(): void {
    this.dataTable()
  }

  dataTable() {
    const that = this;
    let pageLength = that.page_length == null ? 30 : that.page_length;
    that.page_length = null;
    this.dtOptions = {
      dom: "['<l>', Bfrtip']",
      buttons: ["excel", "colvis"],
      pagingType: "full_numbers",
      pageLength: pageLength,
      aLengthMenu: [
        [10, 25, 50, 100000],
        [10, 25, 50, "All"],
      ],
      serverSide: true,
      processing: true,
      displayStart:
        that.currentPage == null
          ? null
          : that.currentPage * pageLength - pageLength,
      columns: this.user != null ? this.getColumns() : this.getColumnsAdmin(),
      ajax: (dataTablesParameters: any, callback) => {
        pageLength = dataTablesParameters.length;
        that.params.page =
          that.currentPage == null
            ? dataTablesParameters.start / pageLength + 1
            : that.currentPage;
        that.currentPage = null;
        that.params.perPage = dataTablesParameters.length;
        this.searchInput = dataTablesParameters.search.value;
        const tri =
          dataTablesParameters.columns[dataTablesParameters.order[0].column]
            .data;
        const order = dataTablesParameters.order[0].dir;
        this.user_id = this.user == null ? null : this.user.user_id;

        if (this.user != null)
          this.busy = this.surveyService
            .getAllSurverys(
              this.params.page,
              this.params.perPage,
              this.searchInput,
              this.user_id
            )
            .subscribe(
              (data: any) => {
                this.surveys = data.data;
                callback({
                  recordsTotal: data.to,
                  recordsFiltered: data.total,
                  data: data.data,
                });
              },
              (error) => { }
            );
        else
          this.busy = this.surveyService
            .getAllSurverysAdmin(
              this.params.page,
              this.params.perPage,
              this.searchInput,
              this.user_id,
              this.type
            )
            .subscribe(
              (data: any) => {
                this.surveys = data.data;
                callback({
                  recordsTotal: data.to,
                  recordsFiltered: data.total,
                  data: data.data,
                });
              },
              (error) => { }
            );
      },

      rowCallback: (row: Node, data: any[] | Object, index: number) => {
      },
    };
  }


  getColumns() {
    const that = this;
    const columns = [
      {
        data: "date",
        render: function (data, type, row) {
          return row.interview_date_first;
        },
      },
      {
        data: "raison_sociale",
        render: function (data, type, row) {
          let matircule = row.company.raison_sociale == -1 ? 'NSP/Refus' : row.company.raison_social
          return matircule;
        },
      },
      {
        data: "Governorat",
        render: function (data, type, row) {
          return row.datasheet?.governorate == null ? '-' : row.datasheet?.governorate
        },
      },
      {
        data: "Activité principale",
        render: function (data, type, row) {
          return row?.main_activities[0]?.main_activity_label?.label
        },
      },
      {
        data: "Activité secendaires",
        render: function (data, type, row, index) {
        return row?.secondary_activities[0]?.main_activity_label?.label ?  row?.secondary_activities[0]?.main_activity_label?.label : 'Pas d\'activité secondaire'
        },
      },
      {
        data: "Email",
        render: function (data, type, row) {
          return row?.datasheet?.email;
        },
      },
      {
        data: "website",
        render: function (data, type, row) {
          let website = row?.datasheet?.website == -1 ? ' NSP/Refus' : row?.datasheet?.website
          return website;
        },
      },



      {
        data: "Adresse exacte",
        render: function (data, type, row) {
          return row?.datasheet?.exact_adress;
        },
      },
      {
        data: "actions",
        render: function (data, type, row) {
          return (
            `<ul class="list-inline font-size-20 contact-links mb-0">
                    <li class="list-inline-item px-2" >
                      <a title="Editer"><i class="bx bx-edit-alt 
                      "  view-person-id="` +
            row.survey_id +
            `"></i></a>
                    </li>
                  </ul>`
          );
        },
      },
    ];

    return columns;
  }
  getColumnsAdmin() {
    const that = this;
    const columns = [
      {
        data: "date",
        render: function (data, type, row) {
          return row?.interview_date_first;
        },
      },
      {
        data: "raison_sociale",
        render: function (data, type, row) {
          let matircule = row?.company?.raison_sociale == -1 ? 'NSP/Refus' : row?.company?.raison_social
          return matircule;
        },
      },
      {
        data: "Governorat",
        render: function (data, type, row) {
          return row?.datasheet?.governorate == null ? '-' : row?.datasheet?.governorate
        },
      },
      {
        data: "Activité principale",
        render: function (data, type, row) {
          return row?.main_activities[0]?.main_activity_label?.label
        },
      },
      {
        data: "Activité secendaires",
        render: function (data, type, row, index) {
        return row?.secondary_activities[0]?.main_activity_label?.label ?  row?.secondary_activities[0]?.main_activity_label?.label : 'Pas d\'activité secondaire'
        },
      },
      {
        data: "Email",
        render: function (data, type, row) {
          return row?.datasheet?.email;
        },
      },
      {
        data: "website",
        render: function (data, type, row) {
          let website = row?.datasheet?.website == -1 ? ' NSP/Refus' : row?.datasheet?.website
          return website;
        },
      },



      {
        data: "Adresse exacte",
        render: function (data, type, row) {
          return row?.datasheet?.exact_adress;
        },
      },
      {
        data: "actions",
        render: function (data, type, row) {
          return (
            `<ul class="list-inline font-size-20 contact-links mb-0">
                    <li class="list-inline-item px-2" >
                      <a title="Edit"><i class="bx bx-edit-alt 
                      "  view-person-id="` +
            row.survey_id +
            `"></i></a>
                    </li>
                  </ul>`
          );
        },
      },
    ];

    return columns;
  }
  ngAfterViewInit(): void {
    this.renderer.listen("document", "click", (event) => {
      if (event.target.hasAttribute("view-person-id")) {
        if (this.user)
          this.router.navigate([
            "/user/edit/" +
            event.target.getAttribute("view-person-id"),
          ]);
        else
          this.router.navigate([
            "/admin/edit/" +
            event.target.getAttribute("view-person-id"),
          ]);
      }
    });
  }

  getAllSurverysTOExcel() {
    this.busy = this.surveyService
      .getAllSurverysTOExcel(
        this.params.page,
        50,
        this.searchInput,
        this.user_id,
        this.type      )
      .subscribe(
        (data: any) => {
          if (data.data.length > 0) {
            this.params.page++;
            this.surveysData = [...data.data, ...this.surveysData]
            this.getAllSurverysTOExcel()
          }
          else
            this.generateExcelOrganizations()
        },
        (error) => { }
      );
  }

  private generateExcelOrganizations() {
    for (let i = 0; i < this.surveysData.length; i++) {
      this.surveyExcelData.push([
        this.surveysData[i].type == '2' ? 'Automobile' : 'Aéronautique',
        this.surveysData[i].company.matricule_fiscale != '-1' ? this.surveysData[i].company.matricule_fiscale : 'NSP/Refus',
        this.surveysData[i].company.raison_social,
        this.surveysData[i].company.year_of_creation,
        this.surveysData[i].datasheet.governorate,
        this.surveysData[i].company.has_feliales == '1' && this.surveysData[i].company.which_governorate ? this.surveysData[i].company.which_governorate : 'Non',
        Utils.getReponse(this.surveysData[i].company.is_offshore),
        Utils.getReponse(this.surveysData[i].company.manager_gender),
        Utils.getReponse(this.surveysData[i].company.regime),
        this.surveysData[i]?.main_activities[0]?.main_activity_label.label,
        this.surveysData[i]?.sub_activities[0]?.sub_activity_label.label,
        this.surveysData[i]?.company_activity.main_product,
        this.surveysData[i].company_activity.product_one_ca_pourcentage != '-1' ? this.surveysData[i].company_activity.product_one_ca_pourcentage : 'NSP/Refus',
        this.surveysData[i]?.secondary_activities[0]?.main_activity_label?.label ? this.surveysData[i]?.secondary_activities[0]?.main_activity_label?.label : 'Pas d\'activité secondaire',
        this.surveysData[i]?.secondary_sub_activities[0]?.sub_activity_label.label,
        this.surveysData[i]?.company_activity.secondary_product ? this.surveysData[i]?.company_activity.secondary_product : '-',
        this.surveysData[i].company_activity.annual_volume_product_two ? this.surveysData[i].company_activity.annual_volume_product_two != '-1' ? this.surveysData[i].company_activity.annual_volume_product_two : '-' : '-',
        this.surveysData[i].other_activities ? this.surveysData[i].other_activities : '-',
        this.surveysData[i].tunisian_websites.length > 0 ? Utils.getwebSites(this.surveysData[i].tunisian_websites) : '-',
        this.surveysData[i].other_websites.length > 0 ? Utils.getwebSites(this.surveysData[i].other_websites) : '-',
        this.surveysData[i].foreign_sites_countries.length > 0 ? Utils.getSitesCountries(this.surveysData[i].foreign_sites_countries) : 'Aucun Pays',
        Utils.getChiffreAffaire(this.surveysData[i]?.company_activity.chiffre_affaire),
        this.surveysData[i].company_activity.ca_automobile != '-1' ? this.surveysData[i].company_activity.ca_automobile : 'NSP/Refus',
        this.surveysData[i].company_activity.ca_export != '-1' ? this.surveysData[i].company_activity.ca_export : 'NSP/Refus',
        this.surveysData[i].company_activity.rd_in_ca != '-1' ? this.surveysData[i].company_activity.rd_in_ca : 'NSP/Refus',
        this.surveysData[i].company_activity.foreign_ownership_in_the_capital != '-1' ? this.surveysData[i].company_activity.foreign_ownership_in_the_capital : 'NSP/Refus',
        this.surveysData[i].countries.length > 0 ? Utils.getSitesCountries(this.surveysData[i].countries) : 'Aucun Pays',
        this.surveysData[i].countries.length > 0 ? Utils.getCountriesParticipantPercountry(this.surveysData[i].countries) : 'NSP/Refus',
        this.surveysData[i].company_activity.installed_production_capacity != '-1' ? this.surveysData[i].company_activity.installed_production_capacity : 'NSP/Refus',
        this.surveysData[i].production_destination.length > 0 ? Utils.getproductionDestination(this.surveysData[i].production_destination) : 'NSP/Refus',
        this.surveysData[i].production_destination.length > 0 ? Utils.getSitesCountries(this.surveysData[i].production_destination) : 'NSP/Refus',
        this.surveysData[i].production_destination.length > 0 ? Utils.getproductionDestinationCategory(this.surveysData[i].production_destination) : 'NSP/Refus',
        this.surveysData[i].company_activity.nb_clients_constructors,
        this.surveysData[i].company_activity.clients_constructors,
        this.surveysData[i].company_activity.nb_clients_suppliers,
        this.surveysData[i].company_activity.clients_suppliers,
        this.surveysData[i].company_activity.nb_other_clients,
        this.surveysData[i].activity.total_number_direct_employees,
        this.surveysData[i].activity.men_pourcentage_direct_employees,
        this.surveysData[i].activity.women_pourcentage_direct_employees,
        this.surveysData[i].activity.total_number_indirect_employees,
        this.surveysData[i].activity.pourcentage_executives != '-1' ? this.surveysData[i].activity.pourcentage_executives : 'NSP/Refus',
        this.surveysData[i].activity.pourcentage_technicians_workers != '-1' ? this.surveysData[i].activity.pourcentage_technicians_workers : 'NSP/Refus',
        this.surveysData[i].activity.pourcentage_ouvriers != '-1' ? this.surveysData[i].activity.pourcentage_ouvriers : 'NSP/Refus',
        this.surveysData[i].activity.pourcentage_technique_departement != '-1' ? this.surveysData[i].activity.pourcentage_technique_departement : 'NSP/Refus',
        this.surveysData[i].activity.pourcentage_commercial_departement != '-1' ? this.surveysData[i].activity.pourcentage_commercial_departement : 'NSP/Refus',
        this.surveysData[i].activity.pourcentage_administratif_departement != '-1' ? this.surveysData[i].activity.pourcentage_administratif_departement : 'NSP/Refus',
        this.surveysData[i].activity.pourcentage_cdi != '-1' ? this.surveysData[i].activity.pourcentage_cdi : 'NSP/Refus',
        this.surveysData[i].activity.pourcentage_cdd != '-1' ? this.surveysData[i].activity.pourcentage_cdd : 'NSP/Refus',
        this.surveysData[i].activity.pourcentage_sivp_stage_karama != '-1' ? this.surveysData[i].activity.pourcentage_sivp_stage_karama : 'NSP/Refus',
        this.surveysData[i].activity.pourcentage_others != '-1' ? this.surveysData[i].activity.pourcentage_others : 'NSP/Refus',
        this.surveysData[i].activity.training_hours_per_employee != '-1' ? this.surveysData[i].activity.training_hours_per_employee : 'NSP/Refus',
        Utils.getReponse(this.surveysData[i].activity.requirement_next_months),
        this.surveysData[i].job.certification_obtained,
        this.surveysData[i].job.certification_in_progress,
        this.surveysData[i].job.avg_local_integration_rate,
        this.surveysData[i].job.other_info_technical_known_how != '-1' ? this.surveysData[i].job.other_info_technical_known_how : 'Aucune autre information',
        Utils.getReponse(this.surveysData[i].job.is_open_to_co_dev),
        this.surveysData[i].job.co2_emission != '-1' ? this.surveysData[i].job.co2_emission : 'NSP/Refus',
        Utils.getReponse(this.surveysData[i].job.internal_waste_recycling),
        Utils.getReponseDechets(this.surveysData[i].job.plastic_waste),
        Utils.getReponseDechets(this.surveysData[i].job.metalic_waste),
        Utils.getReponseDechets(this.surveysData[i].job.textil_waste),
        Utils.getReponseDechets(this.surveysData[i].job.oil_waste),
        Utils.getReponseDechets(this.surveysData[i].job.carbon_waste),
        Utils.getReponseDechets(this.surveysData[i].job.dangerous_waste),
        Utils.getReponseDechets(this.surveysData[i].job.other_waste),
        Utils.getReponse(this.surveysData[i].job.is_expansion),
        Utils.getReponse(this.surveysData[i].job.is_new_products),
        Utils.getReponse(this.surveysData[i].job.is_new_markets),
        Utils.getReponse(this.surveysData[i].job.is_industry_trends),
        this.surveysData[i].job.business_development_vision != '-1' ? this.surveysData[i].job.business_development_vision : 'NSP/Refus',
        this.surveysData[i].company.respondnat_name,
        this.surveysData[i].company.respondnat_gender != '-1' ? this.surveysData[i].company.respondnat_gender == '1' ? 'Homme' : 'Femme' : 'NSP/Refus',
        this.surveysData[i].company.respondnat_age != '-1' ? this.surveysData[i].company.respondnat_gender : 'NSP/Refus',
        this.surveysData[i].company.respondnat_function,
        this.surveysData[i].datasheet.phone != '-1' ? this.surveysData[i].datasheet.phone : 'NSP/Refus',
        this.surveysData[i].datasheet.manager_phone != '-1' ? this.surveysData[i].datasheet.manager_phone : 'NSP/Refus',
        this.surveysData[i].datasheet.fax != '-1' ? this.surveysData[i].datasheet.fax : 'NSP/Refus',
        this.surveysData[i].datasheet.email,
        this.surveysData[i].datasheet.website != '-1' ? this.surveysData[i].datasheet.website : 'NSP/Refus',
        this.surveysData[i].datasheet.exact_adress,
        this.surveysData[i].datasheet.governorate,
        this.surveysData[i].interview_date_first,
        this.surveysData[i].user.first_name + " " + this.surveysData[i].user.last_name
      ]);
    }
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.surveyExcelData);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Result");

    /* save to file */
    XLSX.writeFile(wb, 'surveys_list.xlsx');

    /*reset  excel  table */
    this.surveyExcelData = [
      ['type', "matricule_Fiscal", "raison_sociale", "année_création", "implantation_siège", "Avez-vous des filiales dans les régions?", "Est-ce une société Offshore ?", "Regime ?", "Est-ce que le dirigeant de cette société est ?", "Activité principale", "Sous Activité principale", "Produit principal", "% du CA annuel produit 1", "Activité secondaire", "Sous activité secondaire", "Produit secondaire", "% du CA annuel produit 2", "Avez-vous d’autres activités?", "Sites en Tunisie", 'Sites étrangers', 'Pays d’implantations sites étrangers', 'Chiffre d’affaires en Euros', '% CA dans l’automobile/aéronautique', '% CA à l’export', '% RD dans le CA', '% de la participation étrangère dans le Capital', 'Pays', 'Participation par Pays %', 'Capacité de production installée en %', 'Nom du client (Destinations Production)', 'Pays (Destinations Production)', 'Catégorie (Destinations Production)', 'Nombre (Constructeur)', '% dans le CA (Constructeur)', 'Nombre (Equipementier)', '% dans le CA (Equipementier)', 'Nombre (Tiers)', 'Total(Nombre d’employés directs)', '%Hommes(Nombre d’employés directs)', '%Femmes(Nombre d’employés directs)', 'Total(Nombre d’employés indirects)', '%Cadres', '%Techniciens', '%Ouvriers', '%Technique/Production', '%Commercial', '%Administratif', '%Contrats à durée indéterminée CDI', '%Contrats à durée déterminée CDD', '%SIVP/Karama/stages', '%Autres', 'Nombre d\'heures de formation délivrée par an', 'Avez-vous des recrutements prévus sur 12 mois?', 'Certifications Obtenues', 'Certifications en cours', 'Estimation du taux d’intégration local moyen de production',
        "Autres infos sur le savoir-faire technique de l’Entreprise", "Etes-vous ouverts à du Co-Dev. Avec techno-parcs, universités?", 'Rejet CO2', 'Revalorisation interne des déchets?', 'Déchets Plastique', 'Déchets Métalliques', 'Déchets Textiles et Cuirs', 'Déchets d’Huiles', 'Déchets Papiers et Cartons', 'Déchets Dangereux', 'Autres Déchets', 'Prévoyez-vous une extension durant ces 12 prochains mois ?', 'Prévoyez- vous de développer de nouveaux produits durant ces 12 prochains mois ?', 'Prévoyez-vous de développer de nouveaux marchés durant ces 12 prochains mois ?', 'Travaillez-vous sur une des tendances du secteur ?', 'Quelle est votre vision pour le développement de votre activité', 'Nom du répondant', 'Genre du répondant', 'Age du répondant', 'Fonction du répondant', 'Téléphone', 'Portable gérant', 'Fax', 'Email', 'Site web', 'Adresse exacte', 'Gouvernorat', 'Date de l’entretien', 'Enquêteur'
      ]
    ];

  }
}


export class ParamPangination {
  page: number = 1
  perPage: number = 10;
}