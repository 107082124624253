<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-5">
                {{year}} ©
            </div>
            <div class="col-sm-7">
                <div class="d-none d-sm-block" style="margin-top: -15px;">
                    <img src="assets/images/CHARTE PHOTO.png" alt="" height="40" >
                </div>
            </div>
        </div>
    </div>
</footer>
