import { Component , OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
/**
 *
 */
constructor(public translate: TranslateService,
  private titleService: Title, updates : SwUpdate) {
  translate.setDefaultLang("fr");
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
    this.titleService.setTitle('Enquête');

    updates.available.subscribe(event =>{
      console.log('isOffline')
     })
}
  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");

  }
}
