<div class="container-fluid" [ngBusy]="busy">
  <app-page-title title="Enquête" [breadcrumbItems]="breadCrumbItems"></app-page-title>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <h4 class="card-title"></h4>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">
                <div >
                  <button type="button" class="btn btn-primary" (click)="getAllSurverysTOExcel()" >
                    <i
                    class="mdi mdi-arrow-down-bold-box
                    me-1"></i> {{'ExporterEnquêtes' | translate}}                  
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 table-responsive">
            <table class="table text-nowrap" datatable [dtOptions]="dtOptions">
              <thead>
                <tr>
                  <th>{{'Date'| translate}}</th>
                  <th>{{'Rasison sociale'| translate}}</th>
                  <th>{{'Gouvernorat'| translate}}</th>
                  <th>{{'Activité principale'| translate}}</th>
                  <th>{{'Activité secendaires'| translate}}</th>
                  <th>{{'Email'| translate}}</th>
                  <th>{{'Website'| translate}}</th>
                  <th>{{'Adresse'| translate}}</th>
                  <th>{{'Actions'| translate}}</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>