import { MenuItem } from './menu.model';

export const MENU: MenuItem[] =[
    {
        id: 1,
        label: 'Liste des enquêtes',
        link: '/user/list-sondage',
    },
    {
        id: 1,
        label: 'AddEnquete',
        link: '/user/sondage',
    },

  
];

